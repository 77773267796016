<template>
    <div class="nav-bar-operations">
        <img src="@/assets/logogruas.png" alt="HomePage" @click="backMainPanel" class="logo-button" />
    </div>


<div style="padding-top: 30px;"></div>

    <div>
    <!-- Tab Navigation -->
    <ul class="nav nav-tabs" role="tablist">
      <li class="nav-item">
        <a
          class="nav-link"
          :class="{ active: activeTab === 'tab1' }"
          @click="activeTab = 'tab1'"
          href="#"
        >
          Remolque
        </a>
      </li>

      <li class="nav-item">
        <a
          class="nav-link"
          :class="{ active: activeTab === 'tab3' }"
          @click="activeTab = 'tab3'"
          href="#"
        >
          Auxilio Vial
        </a>
      </li>
      
      <li class="nav-item">
        <a
          class="nav-link"
          :class="{ active: activeTab === 'tab2' }"
          @click="activeTab = 'tab2'"
          href="#"
        >
          Montacargas
        </a>
      </li>

      <li class="nav-item">
        <a
          class="nav-link"
          :class="{ active: activeTab === 'tab4' }"
          @click="activeTab = 'tab4'"
          href="#"
        >
          Lote
        </a>
      </li>
    </ul>


    <!-- Tab Content -->
    <div class="tab-content mt-3">
      <div
        class="tab-pane"
        :class="{ active: activeTab === 'tab1' }"
        v-if="activeTab === 'tab1'"
      >
        
      <div id="ContentForAssistance">
        <div class="text-operations">
        <h2>Órdenes de trabajo: {{ totalAssistanceStatusCounts }}</h2>
        <div class="status-counts small-text">
            Activo: {{ assistanceStatusCounts['Activo'] }}, 
            Cancelado: {{ assistanceStatusCounts['Cancelado'] }}, 
            Programado: {{ assistanceStatusCounts['Programado'] }}, 
            Asignada: {{ assistanceStatusCounts['Asignada'] }}, 
            Completado: {{ assistanceStatusCounts['Completado'] }}
        </div>
        <div class="thermometer" @mouseleave="hideStatusCountAssistance">
            <div class="bar active" :style="{ width: (this.assistanceStatusCounts['Activo'] / (this.assistance_n_inProcessOrders+this.assistance_n_scheduledOrders) * 100) + '%' }"
                @mouseover="showStatusCountAssistance('Activo', $event)"></div>
            <div class="bar cancelled" :style="{ width: (this.assistanceStatusCounts['Cancelado'] / (this.assistance_n_inProcessOrders+this.assistance_n_scheduledOrders) * 100) + '%' }"
                @mouseover="showStatusCountAssistance('Cancelado', $event)"></div>
            <div class="bar scheduled" :style="{ width: (this.assistanceStatusCounts['Programado'] / (this.assistance_n_inProcessOrders+this.assistance_n_scheduledOrders) * 100) + '%' }"
                @mouseover="showStatusCountAssistance('Programado', $event)"></div>
            <div class="bar assigned" :style="{ width: (this.assistanceStatusCounts['Asignada'] / (this.assistance_n_inProcessOrders+this.assistance_n_scheduledOrders) * 100) + '%' }"
                @mouseover="showStatusCountAssistance('Asignada', $event)"></div>
            <div class="bar completed" :style="{ width: (this.assistanceStatusCounts['Completado'] / (this.assistance_n_inProcessOrders+this.assistance_n_scheduledOrders) * 100) + '%' }"
                @mouseover="showStatusCountAssistance('Completado', $event)"></div>
        </div>
    </div>
    <p v-if="assistanceHoverStatusCount" :style="hoverStatusCountStyle" class="status-count-bubble">{{ assistanceHoverStatusCount }}
    </p>
    <div class="action-buttons">
        <button @click="globalfilters" class="action-button">Filtros globales</button>
        <button @click="forkliftfilters" class="action-button">Filtros montacargas</button>
        <button @click="statusUnits" class="action-button">Ver estatus de unidades</button>
        <button @click="pricesRequests" class="action-button">Ver cotizaciones</button>
        <button @click="newOrder" class="action-button">Nueva orden</button>
        <button @click="rastreoUnidades" class="action-button">Rastreo unidades</button>
        <button @click="ordenesLote" class="action-button">Órdenes en lote</button>
    </div>

    <!-- Table section -->
    <div class="table-container">
        <h2>Órdenes en proceso</h2>
        <table class="table">
            <thead>
                <tr>
                    <th>ID <input v-model="assistanceFilters.note_number" @input="applyFiltersAssistance" placeholder="Buscar ID" /></th>
                    <!--disabling date filters until able to implement date range as punctual datetime is not useful-->
                    <th>Fecha y hora de solicitud <!--<input type="datetime-local" v-model="filters.request_time"
                            @change="applyFilters" /> --></th>
                    <th>Cliente <input v-model="assistanceFilters.client" @input="applyFiltersAssistance" placeholder="Buscar Cliente" />
                    </th>
                    <!--
                    Disabling due to complexity. -->
                    <th>Origen
                        <!--<input v-model="filters.source" @input="applyFilters" placeholder="Buscar Origen" />--></th>
                    <th>Destino <!--<input v-model="filters.destination" @input="applyFilters"
                            placeholder="Buscar Destino" />--></th>
                    <th>Hora de inicio <!--<input type="datetime-local" v-model="filters.start_time"
                            @input="applyFilters" /> --></th>
                    <th>Hora de contacto <!-- <input type="datetime-local" v-model="filters.contact_time"
                            @input="applyFilters" /> -->
                    </th>
                    <!--disabling due to complexity-->
                    <th>Unidad 
                        <!--<input v-model="filters.unit_id" @input="applyFilters" placeholder="Buscar Unidad (# Económico)" />-->
                    </th>
                    <!--disabling due to complexity-->
                    <th>Operador <!--<input v-model="filters.operator_employee_ident" @input="applyFilters"
                            placeholder="Buscar Operador (# Económico)" /> --></th>
                    <th>Expediente <input v-model="assistanceFilters.file_number" @input="applyFiltersAssistance"
                            placeholder="Buscar Expediente" /></th>
                    <!--Disbling due to complexity-->
                    <th>Capturista <!--<input v-model="filters.data_entry_clerk" @input="applyFilters"
                            placeholder="Buscar Capturista (Apellido, Nombre)" /> --></th>
                    <th>Tipo<br>
                        <select v-model="assistanceFilters.type" @change="applyFiltersAssistance" multiple>
                            <option value=1> Auxilio víal</option>
                            <option value=2> Remolque</option>
                            <option value=3> Montacargas</option>
                            <option value=4> Lote</option>
                        </select>
                    </th>
                    <th>Estatus <select v-model="assistanceFilters.status" @change="applyFiltersAssistance" multiple>
                            <option value=1> Activo</option>
                            <option value=5> Asignada</option>
                        </select>
                    </th>
                </tr>
            </thead>
            <tbody>
                <tr v-for="(order, index) in assistancePaginatedInProcessOrders" :key="index">
                    <td>
                        <span
                            :style="{ backgroundColor: calculateGradient(order.request_time, order.expected_time), color: 'white', fontWeight: 'bold', padding: '2px 5px', borderRadius: '3px' }"
                            @click="viewNoteDetails(order.type, order.note_ident)">{{
                                order.note_number }}</span>
                    </td> <!-- ID -->
                    <td><b>Fecha:</b>{{ order.request_time.substring(8, 10) }}/{{ order.request_time.substring(5, 7)
                        }}/{{ order.request_time.substring(0, 4) }} <b>Hora: </b>{{ order.request_time.substring(11, 16)
                        }}</td> <!-- Request time -->
                    <td>{{ order.client }}</td> <!-- Client -->
                    <td>{{ order.source.neighborhood }}, {{ order.source.city }}, {{ order.source.state }}</td>
                    <!-- Source -->
                    <td>{{ order.destination.neighborhood }}, {{ order.destination.city }}, {{ order.destination.state
                        }}</td> <!-- Destination -->
                    <td><!--<b>Fecha: </b><span v-if="order.start_time"> {{ order.start_time.substring(8, 10) }}/{{
                        order.start_time.substring(5, 7) }}/{{ order.start_time.substring(0, 4) }}</span> <span
                            v-else> <b> </b> </span> <b>Hora: </b>-->{{ order.start_time.substring(11, 16) }}</td> <!--Start time-->
                    <td><!--<b>Fecha: </b><span v-if="order.contact_time"> {{ order.contact_time.substring(8, 10) }}/{{
                        order.contact_time.substring(5, 7) }}/{{ order.contact_time.substring(0, 4) }}</span> <span
                            v-else> <b> </b> </span> <b>Hora: </b>-->{{ order.contact_time.substring(11, 16) }}</td> <!--Contact time-->
                    <td>{{ order.unit_id }}</td> <!-- Unit -->
                    <td><b>ID:</b>{{ order.operator_employee_ident }} </td> <!-- Operator -->
                    <td>{{ order.file_number }}</td> <!-- File number -->
                    <td>{{ order.data_entry_clerk }}</td> <!-- Data entry clerk -->
                    <td>{{ order.type === 'Asistencia víal' ? 'Remolque' : order.type }}</td> <!-- type -->
                    <td>
                        <span v-if="order.status === 'Activo'"
                            style="background-color: green; color: white; padding: 2px 5px; border-radius: 3px;">Activo</span>
                        <span v-else-if="order.status === 'Asignada'"
                            style="background-color: #5eff00; color: black; padding: 2px 5px; border-radius: 3px;">Asignada</span>
                        <span v-else-if="order.status === 'Cancelado'"
                            style="background-color: red; color: white; padding: 2px 5px; border-radius: 3px;">Cancelado</span>
                        <span v-else-if="order.status === 'Programado'"
                            style="background-color: #8A2BE2; color: white; padding: 2px 5px; border-radius: 3px;">Programado</span>
                        <span v-else-if="order.status === 'Completado'"
                            style="background-color: blue; color: white; padding: 2px 5px; border-radius: 3px;">Completado</span>
                        <span v-else>{{ order.status }}</span> <!-- Status -->
                    </td>
                </tr>
            </tbody>
        </table>
        <div class="pagination-container">
            <button @click="prevPageAssistance" :disabled="assistanceCurrentPage === 1">
                <img src="@/assets/angulo-izquierdo.png" alt="Previous Page" />
            </button>
            <span v-for="pageNumber in assistanceTotalInProcessPages" :key="pageNumber" @click="goToPageAssistance(pageNumber)"
                :class="{ active: pageNumber === assistanceCurrentPage }">{{ pageNumber }}</span>
            <button @click="nextPageAssistance" :disabled="assistanceCurrentPage >= assistanceTotalInProcessPages">
                <img src="@/assets/angulo-derecho.png" alt="Next Page" />
            </button>
        </div>
    </div>

    <!-- Table for scheduled orders -->
    <div class="table-container">
        <h2>Órdenes pendientes</h2>
        <table class="table">
            <thead>
                <tr>
                    <th>ID</th>
                    <th>Fecha y hora de solicitud</th>
                    <th>Cliente</th>
                    <th>Origen</th>
                    <th>Destino</th>
                    <th>Hora de inicio</th>
                    <th>Hora de contacto</th>
                    <th>Unidad</th>
                    <th>Operador</th>
                    <th>Expediente</th>
                    <th>Capturista</th>
                    <th>Tipo</th>
                    <th>Estatus</th>
                </tr>
            </thead>
            <tbody>
                <tr v-for="(order, index) in assistancePaginatedScheduledOrders" :key="index">
                    <td>
                        <span
                            :style="{ backgroundColor: calculateGradient(order.request_time, order.expected_time), color: 'white', fontWeight: 'bold', padding: '2px 5px', borderRadius: '3px' }"
                            @click="viewNoteDetails(order.type, order.note_ident)">{{
                            order.note_number }}</span>
                    </td> <!-- ID -->
                    <td><b>Fecha:</b>{{ order.request_time.substring(8, 10) }}/{{ order.request_time.substring(5, 7)
                        }}/{{ order.request_time.substring(0, 4) }} <b>Hora: </b>{{ order.request_time.substring(11, 16)
                        }}</td> <!-- Request time -->
                    <td>{{ order.client }}</td> <!-- Client -->
                    <td>{{ order.source.neighborhood }}, {{ order.source.city }}, {{ order.source.state }}</td>
                    <!-- Source -->
                    <td>{{ order.destination.neighborhood }}, {{ order.destination.city }}, {{ order.destination.state
                        }}</td> <!-- Destination -->
                    <td><!--<b>Fecha: </b><span v-if="order.start_time"> {{ order.start_time.substring(8, 10) }}/{{
                            order.start_time.substring(5, 7) }}/{{ order.start_time.substring(0, 4) }}</span> <span
                            v-else> <b> </b> </span> <b>Hora: </b>-->{{ order.start_time.substring(11, 16) }}</td>
                    <!-- Start time -->
                    <td><!--<b>Fecha: </b><span v-if="order.contact_time"> {{ order.contact_time.substring(8, 10) }}/{{
                            order.contact_time.substring(5, 7) }}/{{ order.contact_time.substring(0, 4) }}</span> <span
                            v-else> <b> </b> </span> <b>Hora: </b>-->{{ order.contact_time.substring(11, 16) }}</td>
                    <!-- Contact time -->
                    <td>{{ order.unit_id }}</td> <!-- Unit -->
                    <td><b>ID:</b>{{ order.operator_employee_ident}} </td> <!-- Operator -->
                    <td>{{ order.file_number }}</td> <!-- File number -->
                    <td>{{ order.data_entry_clerk }}</td> <!-- Data entry clerk -->
                    <td>{{ order.type }}</td> <!-- type -->
                    <td>
                        <span v-if="order.status === 'Activo'"
                            style="background-color: green; color: white; padding: 2px 5px; border-radius: 3px;">Activo</span>
                        <span v-else-if="order.status === 'Asignada'"
                            style="background-color: #5eff00; color: black; padding: 2px 5px; border-radius: 3px;">Asignada</span>
                        <span v-else-if="order.status === 'Cancelado'"
                            style="background-color: red; color: white; padding: 2px 5px; border-radius: 3px;">Cancelado</span>
                        <span v-else-if="order.status === 'Programado'"
                            style="background-color: #8A2BE2; color: white; padding: 2px 5px; border-radius: 3px;">Programado</span>
                        <span v-else-if="order.status === 'Completado'"
                            style="background-color: blue; color: white; padding: 2px 5px; border-radius: 3px;">Completado</span>
                        <span v-else>{{ order.status }}</span> <!-- Status -->
                    </td>
                </tr>
            </tbody>
        </table>
        <div class="pagination-container">
            <button @click="prevScheduledPageAssistance" :disabled="assistanceCurrentScheduledPage === 1">
                <img src="@/assets/angulo-izquierdo.png" alt="Previous Page" />
            </button>
            <span v-for="pageNumberScheduled in assistanceTotalScheduledPages" :key="pageNumberScheduled"
                @click="goToScheduledPageAssistance(pageNumberScheduled)"
                :class="{ active: pageNumberScheduled === assistanceCurrentScheduledPage }">{{ pageNumberScheduled }}</span>
            <button @click="nextScheduledPageAssistance" :disabled="assistanceCurrentScheduledPage >= assistanceTotalScheduledPages">
                <img src="@/assets/angulo-derecho.png" alt="Next Page" />
            </button>
        </div>
    </div>
      </div>


      </div>
      <div
        class="tab-pane"
        :class="{ active: activeTab === 'tab3' }"
        v-if="activeTab === 'tab3'"
      >
        
      <div id="ContentForAuxiliar">
        <div class="text-operations">
        <h2>Órdenes de trabajo: {{ totalAuxiliarStatusCounts }}</h2>
        <div class="status-counts small-text">
            Activo: {{ auxiliarStatusCounts['Activo'] }}, 
            Cancelado: {{ auxiliarStatusCounts['Cancelado'] }}, 
            Programado: {{ auxiliarStatusCounts['Programado'] }}, 
            Asignada: {{ auxiliarStatusCounts['Asignada'] }}, 
            Completado: {{ auxiliarStatusCounts['Completado'] }}
        </div>
        <div class="thermometer" @mouseleave="hideStatusCountAuxiliar">
            <div class="bar active" :style="{ width: (this.auxiliarStatusCounts['Activo'] / (this.auxiliar_n_inProcessOrders+this.auxiliar_n_scheduledOrders) * 100) + '%' }"
                @mouseover="showStatusCountAuxiliar('Activo', $event)"></div>
            <div class="bar cancelled" :style="{ width: (this.auxiliarStatusCounts['Cancelado'] / (this.auxiliar_n_inProcessOrders+this.auxiliar_n_scheduledOrders) * 100) + '%' }"
                @mouseover="showStatusCountAuxiliar('Cancelado', $event)"></div>
            <div class="bar scheduled" :style="{ width: (this.auxiliarStatusCounts['Programado'] / (this.auxiliar_n_inProcessOrders+this.auxiliar_n_scheduledOrders) * 100) + '%' }"
                @mouseover="showStatusCountAuxiliar('Programado', $event)"></div>
                <div class="bar assigned" :style="{ width: (this.auxiliarStatusCounts['Asignada'] / (this.auxiliar_n_inProcessOrders+this.auxiliar_n_scheduledOrders) * 100) + '%' }"
                @mouseover="showStatusCountAuxiliar('Asignada', $event)"></div>
            <div class="bar completed" :style="{ width: (this.auxiliarStatusCounts['Completado'] / (this.auxiliar_n_inProcessOrders+this.auxiliar_n_scheduledOrders) * 100) + '%' }"
                @mouseover="showStatusCountAuxiliar('Completado', $event)"></div>
        </div>
    </div>
    <p v-if="auxiliarHoverStatusCount" :style="hoverStatusCountStyle" class="status-count-bubble">{{ auxiliarHoverStatusCount }}
    </p>
    <div class="action-buttons">
        <button @click="globalfilters" class="action-button">Filtros globales</button>
        <button @click="forkliftFilters" class="action-button">Filtros montacargas</button>
        <button @click="statusUnits" class="action-button">Ver estatus de unidades</button>
        <button @click="pricesRequests" class="action-button">Ver cotizaciones</button>
        <button @click="newOrder" class="action-button">Nueva orden</button>
        <button @click="rastreoUnidades" class="action-button">Rastreo unidades</button>
        <button @click="ordenesLote" class="action-button">Órdenes en lote</button>
    </div>

    <!-- Table section -->
    <div class="table-container">
        <h2>Órdenes en proceso</h2>
        <table class="table">
            <thead>
                <tr>
                    <th>ID <input v-model="auxiliarFilters.note_number" @input="applyFiltersAuxiliar" placeholder="Buscar ID" /></th>
                    <!--disabling date filters until able to implement date range as punctual datetime is not useful-->
                    <th>Fecha y hora de solicitud <!--<input type="datetime-local" v-model="filters.request_time"
                            @change="applyFilters" /> --></th>
                    <th>Cliente <input v-model="auxiliarFilters.client" @input="applyFiltersAuxiliar" placeholder="Buscar Cliente" />
                    </th>
                    <!--
                    Disabling due to complexity. -->
                    <th>Origen
                        <!--<input v-model="filters.source" @input="applyFilters" placeholder="Buscar Origen" />--></th>
                    <th>Servicio <!--<input v-model="filters.destination" @input="applyFilters"
                            placeholder="Buscar Destino" />--></th>
                    <th>Hora de inicio <!--<input type="datetime-local" v-model="filters.start_time"
                            @input="applyFilters" /> --></th>
                    <th>Hora de contacto <!-- <input type="datetime-local" v-model="filters.contact_time"
                            @input="applyFilters" /> -->
                    </th>
                    <!--disabling due to complexity-->
                    <th>Unidad 
                        <!--<input v-model="filters.unit_id" @input="applyFilters" placeholder="Buscar Unidad (# Económico)" />-->
                    </th>
                    <!--disabling due to complexity-->
                    <th>Operador <!--<input v-model="filters.operator_employee_ident" @input="applyFilters"
                            placeholder="Buscar Operador (# Económico)" /> --></th>
                    <th>Expediente <input v-model="auxiliarFilters.file_number" @input="applyFiltersAuxiliar"
                            placeholder="Buscar Expediente" /></th>
                    <!--Disbling due to complexity-->
                    <th>Capturista <!--<input v-model="filters.data_entry_clerk" @input="applyFilters"
                            placeholder="Buscar Capturista (Apellido, Nombre)" /> --></th>
                    <th>Tipo<br>
                        <select v-model="auxiliarFilters.type" @change="applyFiltersAuxiliar" multiple>
                            <option value=1> Auxilio víal</option>
                            <option value=2> Remolque</option>
                            <option value=3> Montacargas</option>
                            <option value=4> Lote</option>
                        </select>
                    </th>
                    <th>Estatus <select v-model="auxiliarFilters.status" @change="applyFiltersAuxiliar" multiple>
                            <option value=1> Activo</option>
                            <option value=5> Asignada</option>
                        </select>
                    </th>
                </tr>
            </thead>
            <tbody>
                <tr v-for="(order, index) in auxiliarPaginatedInProcessOrders" :key="index">
                    <td>
                        <span
                            :style="{ backgroundColor: calculateGradient(order.request_time, order.expected_time), color: 'white', fontWeight: 'bold', padding: '2px 5px', borderRadius: '3px' }"
                            @click="viewNoteDetails(order.type, order.note_ident)">{{
                                order.note_number }}</span>
                    </td> <!-- ID -->
                    <td><b>Fecha:</b>{{ order.request_time.substring(8, 10) }}/{{ order.request_time.substring(5, 7)
                        }}/{{ order.request_time.substring(0, 4) }} <b>Hora: </b>{{ order.request_time.substring(11, 16)
                        }}</td> <!-- Request time -->
                    <td>{{ order.client }}</td> <!-- Client -->
                    <td>{{ order.source.neighborhood }}, {{ order.source.city }}, {{ order.source.state }}</td>
                    <!-- Source -->
                    <td>{{ order.aux_requested_service }}</td> <!-- Destination -->
                    <td><!--<b>Fecha: </b><span v-if="order.start_time"> {{ order.start_time.substring(8, 10) }}/{{
                        order.start_time.substring(5, 7) }}/{{ order.start_time.substring(0, 4) }}</span> <span
                            v-else> <b> </b> </span> <b>Hora: </b>-->{{ order.start_time.substring(11, 16) }}</td> <!--Start time-->
                    <td><!--<b>Fecha: </b><span v-if="order.contact_time"> {{ order.contact_time.substring(8, 10) }}/{{
                        order.contact_time.substring(5, 7) }}/{{ order.contact_time.substring(0, 4) }}</span> <span
                            v-else> <b> </b> </span> <b>Hora: </b>-->{{ order.contact_time.substring(11, 16) }}</td> <!--Contact time-->
                    <td>{{ order.unit_id }}</td> <!-- Unit -->
                    <td><b>ID:</b>{{ order.operator_employee_ident }} </td> <!-- Operator -->
                    <td>{{ order.file_number }}</td> <!-- File number -->
                    <td>{{ order.data_entry_clerk }}</td> <!-- Data entry clerk -->
                    <td>{{ order.type }}</td> <!-- type -->
                    <td>
                        <span v-if="order.status === 'Activo'"
                            style="background-color: green; color: white; padding: 2px 5px; border-radius: 3px;">Activo</span>
                        <span v-else-if="order.status === 'Asignada'"
                            style="background-color: #5eff00; color: black; padding: 2px 5px; border-radius: 3px;">Asignada</span>
                        <span v-else-if="order.status === 'Cancelado'"
                            style="background-color: red; color: white; padding: 2px 5px; border-radius: 3px;">Cancelado</span>
                        <span v-else-if="order.status === 'Programado'"
                            style="background-color: #8A2BE2; color: white; padding: 2px 5px; border-radius: 3px;">Programado</span>
                        <span v-else-if="order.status === 'Completado'"
                            style="background-color: blue; color: white; padding: 2px 5px; border-radius: 3px;">Completado</span>
                        <span v-else>{{ order.status }}</span> <!-- Status -->
                    </td>
                </tr>
            </tbody>
        </table>
        <div class="pagination-container">
            <button @click="prevPageAuxiliar" :disabled="auxiliarCurrentPage === 1">
                <img src="@/assets/angulo-izquierdo.png" alt="Previous Page" />
            </button>
            <span v-for="pageNumber in auxiliarTotalInProcessPages" :key="pageNumber" @click="goToPageAuxiliar(pageNumber)"
                :class="{ active: pageNumber === auxiliarCurrentPage }">{{ pageNumber }}</span>
            <button @click="nextPageAuxiliar" :disabled="auxiliarCurrentPage >= auxiliarTotalInProcessPages">
                <img src="@/assets/angulo-derecho.png" alt="Next Page" />
            </button>
        </div>
    </div>

    <!-- Table for scheduled orders -->
    <div class="table-container">
        <h2>Órdenes pendientes</h2>
        <table class="table">
            <thead>
                <tr>
                    <th>ID</th>
                    <th>Fecha y hora de solicitud</th>
                    <th>Cliente</th>
                    <th>Origen</th>
                    <th>Servicio</th>
                    <th>Hora de inicio</th>
                    <th>Hora de contacto</th>
                    <th>Unidad</th>
                    <th>Operador</th>
                    <th>Expediente</th>
                    <th>Capturista</th>
                    <th>Tipo</th>
                    <th>Estatus</th>
                </tr>
            </thead>
            <tbody>
                <tr v-for="(order, index) in auxiliarPaginatedScheduledOrders" :key="index">
                    <td>
                        <span
                        :style="{ backgroundColor: calculateGradient(order.request_time, order.expected_time), color: 'white', fontWeight: 'bold', padding: '2px 5px', borderRadius: '3px' }"
                        @click="viewNoteDetails(order.type, order.note_ident)">{{
                            order.note_number }}</span>
                    </td> <!-- ID -->
                    <td><b>Fecha:</b>{{ order.request_time.substring(8, 10) }}/{{ order.request_time.substring(5, 7)
                        }}/{{ order.request_time.substring(0, 4) }} <b>Hora: </b>{{ order.request_time.substring(11, 16)
                        }}</td> <!-- Request time -->
                    <td>{{ order.client }}</td> <!-- Client -->
                    <td>{{ order.source.neighborhood }}, {{ order.source.city }}, {{ order.source.state }}</td>
                    <!-- Source -->
                    <td>{{ order.aux_requested_service }}</td> <!-- Destination -->
                    <td><!--<b>Fecha: </b><span v-if="order.start_time"> {{ order.start_time.substring(8, 10) }}/{{
                            order.start_time.substring(5, 7) }}/{{ order.start_time.substring(0, 4) }}</span> <span
                            v-else> <b> </b> </span> <b>Hora: </b>-->{{ order.start_time.substring(11, 16) }}</td>
                    <!-- Start time -->
                    <td><!--<b>Fecha: </b><span v-if="order.contact_time"> {{ order.contact_time.substring(8, 10) }}/{{
                            order.contact_time.substring(5, 7) }}/{{ order.contact_time.substring(0, 4) }}</span> <span
                            v-else> <b> </b> </span> <b>Hora: </b>-->{{ order.contact_time.substring(11, 16) }}</td>
                    <!-- Contact time -->
                    <td>{{ order.unit_id }}</td> <!-- Unit -->
                    <td><b>ID:</b>{{ order.operator_employee_ident}} </td> <!-- Operator -->
                    <td>{{ order.file_number }}</td> <!-- File number -->
                    <td>{{ order.data_entry_clerk }}</td> <!-- Data entry clerk -->
                    <td>{{ order.type }}</td> <!-- type -->
                    <td>
                        <span v-if="order.status === 'Activo'"
                            style="background-color: green; color: white; padding: 2px 5px; border-radius: 3px;">Activo</span>
                        <span v-else-if="order.status === 'Asignada'"
                            style="background-color: #5eff00; color: black; padding: 2px 5px; border-radius: 3px;">Asignada</span>
                        <span v-else-if="order.status === 'Cancelado'"
                            style="background-color: red; color: white; padding: 2px 5px; border-radius: 3px;">Cancelado</span>
                        <span v-else-if="order.status === 'Programado'"
                            style="background-color: #8A2BE2; color: white; padding: 2px 5px; border-radius: 3px;">Programado</span>
                        <span v-else-if="order.status === 'Completado'"
                            style="background-color: blue; color: white; padding: 2px 5px; border-radius: 3px;">Completado</span>
                        <span v-else>{{ order.status }}</span> <!-- Status -->
                    </td>
                </tr>
            </tbody>
        </table>
        <div class="pagination-container">
            <button @click="prevScheduledPageAuxiliar" :disabled="auxiliarCurrentScheduledPage === 1">
                <img src="@/assets/angulo-izquierdo.png" alt="Previous Page" />
            </button>
            <span v-for="pageNumberScheduled in auxiliarTotalScheduledPages" :key="pageNumberScheduled"
                @click="goToScheduledPageAuxiliar(pageNumberScheduled)"
                :class="{ active: pageNumberScheduled === auxiliarCurrentScheduledPage }">{{ pageNumberScheduled }}</span>
            <button @click="nextScheduledPageAuxiliar" :disabled="auxiliarCurrentScheduledPage >= auxiliarTotalScheduledPages">
                <img src="@/assets/angulo-derecho.png" alt="Next Page" />
            </button>
        </div>
    </div>
      </div>


      </div>
      <div
        class="tab-pane"
        :class="{ active: activeTab === 'tab4' }"
        v-if="activeTab === 'tab4'"
      >
        
      <div id="ContentForLot">
        <div class="text-operations">
        <h2>Órdenes de trabajo: {{ totalLotStatusCounts }}</h2>
        <div class="status-counts small-text">
            Activo: {{ lotStatusCounts['Activo'] }}, 
            Cancelado: {{ lotStatusCounts['Cancelado'] }}, 
            Programado: {{ lotStatusCounts['Programado'] }}, 
            Asignada: {{ lotStatusCounts['Asignada'] }}, 
            Completado: {{ lotStatusCounts['Completado'] }}
        </div>
        <div class="thermometer" @mouseleave="hideStatusCountLot">
            <div class="bar active" :style="{ width: (this.lotStatusCounts['Activo'] / (this.lot_n_inProcessOrders+this.lot_n_scheduledOrders) * 100) + '%' }"
                @mouseover="showStatusCountLot('Activo', $event)"></div>
            <div class="bar cancelled" :style="{ width: (this.lotStatusCounts['Cancelado'] / (this.lot_n_inProcessOrders+this.lot_n_scheduledOrders) * 100) + '%' }"
                @mouseover="showStatusCountLot('Cancelado', $event)"></div>
            <div class="bar scheduled" :style="{ width: (this.lotStatusCounts['Programado'] / (this.lot_n_inProcessOrders+this.lot_n_scheduledOrders) * 100) + '%' }"
                @mouseover="showStatusCountLot('Programado', $event)"></div>
            <div class="bar assigned" :style="{ width: (this.lotStatusCounts['Asignada'] / (this.lot_n_inProcessOrders+this.lot_n_scheduledOrders) * 100) + '%' }"
                @mouseover="showStatusCountLot('Asignada', $event)"></div>
            <div class="bar completed" :style="{ width: (this.lotStatusCounts['Completado'] / (this.lot_n_inProcessOrders+this.lot_n_scheduledOrders) * 100) + '%' }"
                @mouseover="showStatusCountLot('Completado', $event)"></div>
        </div>
    </div>
    <p v-if="lotHoverStatusCount" :style="hoverStatusCountStyle" class="status-count-bubble">{{ lotHoverStatusCount }}
    </p>
    <div class="action-buttons">
        <button @click="globalfilters" class="action-button">Filtros globales</button>
        <button @click="forkliftfilters" class="action-button">Filtros montacargas</button>
        <button @click="statusUnits" class="action-button">Ver estatus de unidades</button>
        <button @click="pricesRequests" class="action-button">Ver cotizaciones</button>
        <button @click="newOrder" class="action-button">Nueva orden</button>
        <button @click="rastreoUnidades" class="action-button">Rastreo unidades</button>
        <button @click="ordenesLote" class="action-button">Órdenes en lote</button>
    </div>

    <!-- Table section -->
    <div class="table-container">
        <h2>Órdenes en proceso</h2>
        <table class="table">
            <thead>
                <tr>
                    <th>ID <input v-model="lotFilters.note_number" @input="applyFiltersLot" placeholder="Buscar ID" /></th>
                    <!--disabling date filters until able to implement date range as punctual datetime is not useful-->
                    <th>Fecha y hora de solicitud <!--<input type="datetime-local" v-model="filters.request_time"
                            @change="applyFilters" /> --></th>
                    <th>Cliente <input v-model="lotFilters.client" @input="applyFiltersLot" placeholder="Buscar Cliente" />
                    </th>
                    <!--
                    Disabling due to complexity. -->
                    <th>Origen
                        <!--<input v-model="filters.source" @input="applyFilters" placeholder="Buscar Origen" />--></th>
                    <th>Destino <!--<input v-model="filters.destination" @input="applyFilters"
                            placeholder="Buscar Destino" />--></th>
                    <th>Hora de inicio <!--<input type="datetime-local" v-model="filters.start_time"
                            @input="applyFilters" /> --></th>
                    <th>Hora de contacto <!-- <input type="datetime-local" v-model="filters.contact_time"
                            @input="applyFilters" /> -->
                    </th>
                    <!--disabling due to complexity-->
                    <th>Unidad 
                        <!--<input v-model="filters.unit_id" @input="applyFilters" placeholder="Buscar Unidad (# Económico)" />-->
                    </th>
                    <!--disabling due to complexity-->
                    <th>Operador <!--<input v-model="filters.operator_employee_ident" @input="applyFilters"
                            placeholder="Buscar Operador (# Económico)" /> --></th>
                    <th>Expediente <input v-model="lotFilters.file_number" @input="applyFiltersLot"
                            placeholder="Buscar Expediente" /></th>
                    <!--Disbling due to complexity-->
                    <th>Capturista <!--<input v-model="filters.data_entry_clerk" @input="applyFilters"
                            placeholder="Buscar Capturista (Apellido, Nombre)" /> --></th>
                    <th>Tipo<br>
                        <select v-model="lotFilters.type" @change="applyFiltersLot" multiple>
                            <option value=1> Auxilio víal</option>
                            <option value=2> Remolque</option>
                            <option value=3> Montacargas</option>
                            <option value=4> Lote</option>
                        </select>
                    </th>
                    <th>Estatus <select v-model="lotFilters.status" @change="applyFiltersLot" multiple>
                            <option value=1> Activo</option>
                            <option value=5> Asignada</option>
                        </select>
                    </th>
                </tr>
            </thead>
            <tbody>
                <tr v-for="(order, index) in lotPaginatedInProcessOrders" :key="index">
                    <td>
                        <span
                            :style="{ backgroundColor: calculateGradient(order.request_time, order.expected_time), color: 'white', fontWeight: 'bold', padding: '2px 5px', borderRadius: '3px' }"
                            @click="viewNoteDetails(order.type, order.note_ident)">{{
                                order.note_number }}</span>
                    </td> <!-- ID -->
                    <td><b>Fecha:</b>{{ order.request_time.substring(8, 10) }}/{{ order.request_time.substring(5, 7)
                        }}/{{ order.request_time.substring(0, 4) }} <b>Hora: </b>{{ order.request_time.substring(11, 16)
                        }}</td> <!-- Request time -->
                    <td>{{ order.client }}</td> <!-- Client -->
                    <td>{{ order.source.neighborhood }}, {{ order.source.city }}, {{ order.source.state }}</td>
                    <!-- Source -->
                    <td>{{ order.destination.neighborhood }}, {{ order.destination.city }}, {{ order.destination.state
                        }}</td> <!-- Destination -->
                    <td><!--<b>Fecha: </b><span v-if="order.start_time"> {{ order.start_time.substring(8, 10) }}/{{
                        order.start_time.substring(5, 7) }}/{{ order.start_time.substring(0, 4) }}</span> <span
                            v-else> <b> </b> </span> <b>Hora: </b>-->{{ order.start_time.substring(11, 16) }}</td> <!--Start time-->
                    <td><!--<b>Fecha: </b><span v-if="order.contact_time"> {{ order.contact_time.substring(8, 10) }}/{{
                        order.contact_time.substring(5, 7) }}/{{ order.contact_time.substring(0, 4) }}</span> <span
                            v-else> <b> </b> </span> <b>Hora: </b>-->{{ order.contact_time.substring(11, 16) }}</td> <!--Contact time-->
                    <td>{{ order.unit_id }}</td> <!-- Unit -->
                    <td><b>ID:</b>{{ order.operator_employee_ident }} </td> <!-- Operator -->
                    <td>{{ order.file_number }}</td> <!-- File number -->
                    <td>{{ order.data_entry_clerk }}</td> <!-- Data entry clerk -->
                    <td>{{ order.type }}</td> <!-- type -->
                    <td>
                        <span v-if="order.status === 'Activo'"
                            style="background-color: green; color: white; padding: 2px 5px; border-radius: 3px;">Activo</span>
                        <span v-else-if="order.status === 'Asignada'"
                            style="background-color: #5eff00; color: black; padding: 2px 5px; border-radius: 3px;">Asignada</span>
                        <span v-else-if="order.status === 'Cancelado'"
                            style="background-color: red; color: white; padding: 2px 5px; border-radius: 3px;">Cancelado</span>
                        <span v-else-if="order.status === 'Programado'"
                            style="background-color: #8A2BE2; color: white; padding: 2px 5px; border-radius: 3px;">Programado</span>
                        <span v-else-if="order.status === 'Completado'"
                            style="background-color: blue; color: white; padding: 2px 5px; border-radius: 3px;">Completado</span>
                        <span v-else>{{ order.status }}</span> <!-- Status -->
                    </td>
                </tr>
            </tbody>
        </table>
        <div class="pagination-container">
            <button @click="prevPageLot" :disabled="lotCurrentPage === 1">
                <img src="@/assets/angulo-izquierdo.png" alt="Previous Page" />
            </button>
            <span v-for="pageNumber in lotTotalInProcessPages" :key="pageNumber" @click="goToPageLot(pageNumber)"
                :class="{ active: pageNumber === lotCurrentPage }">{{ pageNumber }}</span>
            <button @click="nextPageLot" :disabled="lotCurrentPage >= lotTotalInProcessPages">
                <img src="@/assets/angulo-derecho.png" alt="Next Page" />
            </button>
        </div>
    </div>

    <!-- Table for scheduled orders -->
    <div class="table-container">
        <h2>Órdenes pendientes</h2>
        <table class="table">
            <thead>
                <tr>
                    <th>ID</th>
                    <th>Fecha y hora de solicitud</th>
                    <th>Cliente</th>
                    <th>Origen</th>
                    <th>Destino</th>
                    <th>Hora de inicio</th>
                    <th>Hora de contacto</th>
                    <th>Unidad</th>
                    <th>Operador</th>
                    <th>Expediente</th>
                    <th>Capturista</th>
                    <th>Tipo</th>
                    <th>Estatus</th>
                </tr>
            </thead>
            <tbody>
                <tr v-for="(order, index) in lotPaginatedScheduledOrders" :key="index">
                    <td>
                        <span
                            :style="{ backgroundColor: calculateGradient(order.request_time, order.expected_time), color: 'white', fontWeight: 'bold', padding: '2px 5px', borderRadius: '3px' }"
                            @click="viewNoteDetails(order.type, order.note_ident)">{{
                            order.note_number }}</span>
                    </td> <!-- ID -->
                    <td><b>Fecha:</b>{{ order.request_time.substring(8, 10) }}/{{ order.request_time.substring(5, 7)
                        }}/{{ order.request_time.substring(0, 4) }} <b>Hora: </b>{{ order.request_time.substring(11, 16)
                        }}</td> <!-- Request time -->
                    <td>{{ order.client }}</td> <!-- Client -->
                    <td>{{ order.source.neighborhood }}, {{ order.source.city }}, {{ order.source.state }}</td>
                    <!-- Source -->
                    <td>{{ order.destination.neighborhood }}, {{ order.destination.city }}, {{ order.destination.state
                        }}</td> <!-- Destination -->
                    <td><!--<b>Fecha: </b><span v-if="order.start_time"> {{ order.start_time.substring(8, 10) }}/{{
                            order.start_time.substring(5, 7) }}/{{ order.start_time.substring(0, 4) }}</span> <span
                            v-else> <b> </b> </span> <b>Hora: </b>-->{{ order.start_time.substring(11, 16) }}</td>
                    <!-- Start time -->
                    <td><!--<b>Fecha: </b><span v-if="order.contact_time"> {{ order.contact_time.substring(8, 10) }}/{{
                            order.contact_time.substring(5, 7) }}/{{ order.contact_time.substring(0, 4) }}</span> <span
                            v-else> <b> </b> </span> <b>Hora: </b>-->{{ order.contact_time.substring(11, 16) }}</td>
                    <!-- Contact time -->
                    <td>{{ order.unit_id }}</td> <!-- Unit -->
                    <td><b>ID:</b>{{ order.operator_employee_ident}} </td> <!-- Operator -->
                    <td>{{ order.file_number }}</td> <!-- File number -->
                    <td>{{ order.data_entry_clerk }}</td> <!-- Data entry clerk -->
                    <td>{{ order.type }}</td> <!-- type -->
                    <td>
                        <span v-if="order.status === 'Activo'"
                            style="background-color: green; color: white; padding: 2px 5px; border-radius: 3px;">Activo</span>
                        <span v-else-if="order.status === 'Asignada'"
                            style="background-color: #5eff00; color: black; padding: 2px 5px; border-radius: 3px;">Asignada</span>
                        <span v-else-if="order.status === 'Cancelado'"
                            style="background-color: red; color: white; padding: 2px 5px; border-radius: 3px;">Cancelado</span>
                        <span v-else-if="order.status === 'Programado'"
                            style="background-color: #8A2BE2; color: white; padding: 2px 5px; border-radius: 3px;">Programado</span>
                        <span v-else-if="order.status === 'Completado'"
                            style="background-color: blue; color: white; padding: 2px 5px; border-radius: 3px;">Completado</span>
                        <span v-else>{{ order.status }}</span> <!-- Status -->
                    </td>
                </tr>
            </tbody>
        </table>
        <div class="pagination-container">
            <button @click="prevScheduledPageLot" :disabled="lotCurrentScheduledPage === 1">
                <img src="@/assets/angulo-izquierdo.png" alt="Previous Page" />
            </button>
            <span v-for="pageNumberScheduled in lotTotalScheduledPages" :key="pageNumberScheduled"
                @click="goToScheduledPageLot(pageNumberScheduled)"
                :class="{ active: pageNumberScheduled === lotCurrentScheduledPage }">{{ pageNumberScheduled }}</span>
            <button @click="nextScheduledPageLot" :disabled="lotCurrentScheduledPage >= lotTotalScheduledPages">
                <img src="@/assets/angulo-derecho.png" alt="Next Page" />
            </button>
        </div>
    </div>
      </div>


      </div>
      <div
        class="tab-pane"
        :class="{ active: activeTab === 'tab2' }"
        v-if="activeTab === 'tab2'"
      >

      <div id="ContentForForklift">
        <div class="text-operations">
        <h2>Órdenes de trabajo: {{ totalForkliftStatusCounts }}</h2>
        <div class="status-counts small-text">
            Activo: {{ forkliftStatusCounts['Activo'] }}, 
            Cancelado: {{ forkliftStatusCounts['Cancelado'] }}, 
            Programado: {{ forkliftStatusCounts['Programado'] }}, 
            Asignada: {{ forkliftStatusCounts['Asignada'] }}, 
            Completado: {{ forkliftStatusCounts['Completado'] }}
        </div>
        <div class="thermometer" @mouseleave="hideStatusCountForklift">
            <div class="bar active" :style="{ width: (this.forkliftStatusCounts['Activo'] / (this.forklift_n_inProcessOrders+this.forklift_n_scheduledOrders) * 100) + '%' }"
                @mouseover="showStatusCountForklift('Activo', $event)"></div>
            <div class="bar cancelled" :style="{ width: (this.forkliftStatusCounts['Cancelado'] / (this.forklift_n_inProcessOrders+this.forklift_n_scheduledOrders) * 100) + '%' }"
                @mouseover="showStatusCountForklift('Cancelado', $event)"></div>
            <div class="bar scheduled" :style="{ width: (this.forkliftStatusCounts['Programado'] / (this.forklift_n_inProcessOrders+this.forklift_n_scheduledOrders) * 100) + '%' }"
                @mouseover="showStatusCountForklift('Programado', $event)"></div>
                <div class="bar assigned" :style="{ width: (this.forkliftStatusCounts['Asignada'] / (this.forklift_n_inProcessOrders+this.forklift_n_scheduledOrders) * 100) + '%' }"
                @mouseover="showStatusCountForklift('Asignada', $event)"></div>
            <div class="bar completed" :style="{ width: (this.forkliftStatusCounts['Completado'] / (this.forklift_n_inProcessOrders+this.forklift_n_scheduledOrders) * 100) + '%' }"
                @mouseover="showStatusCountForklift('Completado', $event)"></div>
        </div>
    </div>
    <p v-if="forkliftHoverStatusCount" :style="forkliftHoverStatusCountStyle" class="status-count-bubble">{{ forkliftHoverStatusCount }}
    </p>
    <div class="action-buttons">
        <!--IDK if global filtrs should remain here-->
        <button @click="globalfilters" class="action-button">Filtros globales</button>
        <button @click="forkliftfilters" class="action-button">Filtros montacargas</button>
        <button @click="statusUnits" class="action-button">Ver estatus de unidades</button>
        <button @click="pricesRequests" class="action-button">Ver cotizaciones</button>
        <button @click="newOrder" class="action-button">Nueva orden</button>
        <button @click="rastreoUnidades" class="action-button">Rastreo unidades</button>
        <button @click="ordenesLote" class="action-button">Órdenes en lote</button>
    </div>

    <!-- Table section -->
    <div class="table-container">
        <h2>Órdenes en proceso</h2>
        <table class="table">
            <thead>
                <tr>
                    <th>ID <input v-model="forkliftFilters.note_number" @input="applyFiltersForklift" placeholder="Buscar ID" /></th>
                    <!--disabling date filters until able to implement date range as punctual datetime is not useful-->
                    <th>Fecha y hora de solicitud <!--<input type="datetime-local" v-model="filters.request_time"
                            @change="applyFilters" /> --></th>
                    <th>Cliente <input v-model="forkliftFilters.client" @input="applyFiltersForklift" placeholder="Buscar Cliente" />
                    </th>
                    <!--
                    Disabling due to complexity. -->
                    <th>Origen
                        <!--<input v-model="filters.source" @input="applyFilters" placeholder="Buscar Origen" />--></th>

                    <th>Hora de inicio <!--<input type="datetime-local" v-model="filters.start_time"
                            @input="applyFilters" /> --></th>
                    <th>Hora de contacto <!-- <input type="datetime-local" v-model="filters.contact_time"
                            @input="applyFilters" /> -->
                    </th>
                    <!--disabling due to complexity-->
                    <th>Unidad 
                        <!--<input v-model="filters.unit_id" @input="applyFilters" placeholder="Buscar Unidad (# Económico)" />-->
                    </th>
                    <!--disabling due to complexity-->
                    <th>Operador <!--<input v-model="filters.operator_employee_ident" @input="applyFilters"
                            placeholder="Buscar Operador (# Económico)" /> --></th>
                    <th>Expediente <input v-model="forkliftFilters.file_number" @input="applyFiltersForklift"
                            placeholder="Buscar Expediente" /></th>
                    <!--Disbling due to complexity-->
                    <th>Capturista <!--<input v-model="filters.data_entry_clerk" @input="applyFilters"
                            placeholder="Buscar Capturista (Apellido, Nombre)" /> --></th>
                    <th>Estatus <select v-model="forkliftFilters.note_status" @change="applyFiltersForklift" multiple>
                            <option value=1> Activo</option>
                            <option value=5> Asignada</option>
                        </select>
                    </th>
                </tr>
            </thead>
            <tbody>
                <tr v-for="(order, index) in forkliftPaginatedInProcessOrders" :key="index">
                    <td>
                        <span
                            style="background-color: #FFC434; color: black; font-weight: bold; padding: 2px 5px; border-radius: 3px;"
                            @click="viewForkliftNoteDetails(order.note_ident)">{{
                                order.note_number }}</span>
                    </td> <!-- ID -->
                    <td><b>Fecha:</b>{{ order.request_time.substring(8, 10) }}/{{ order.request_time.substring(5, 7)
                        }}/{{ order.request_time.substring(0, 4) }} <b>Hora: </b>{{ order.request_time.substring(11, 16)
                        }}</td> <!-- Request time -->
                    <td>{{ order.client }}</td> <!-- Client -->
                    <td>{{ order.source.neighborhood }}, {{ order.source.city }}, {{ order.source.state }}</td>
                    <!-- Source -->
                    <td><!--<b>Fecha: </b><span v-if="order.start_time"> {{ order.start_time.substring(8, 10) }}/{{
                        order.start_time.substring(5, 7) }}/{{ order.start_time.substring(0, 4) }}</span> <span
                            v-else> <b> </b> </span> <b>Hora: </b>-->{{ order.start_time.substring(11, 16) }}</td> <!--Start time-->
                    <td><!--<b>Fecha: </b><span v-if="order.contact_time"> {{ order.contact_time.substring(8, 10) }}/{{
                        order.contact_time.substring(5, 7) }}/{{ order.contact_time.substring(0, 4) }}</span> <span
                            v-else> <b> </b> </span> <b>Hora: </b>-->{{ order.contact_time.substring(11, 16) }}</td> <!--Contact time-->
                    <td>{{ order.unit_id }}</td> <!-- Unit -->
                    <td><b>ID:</b>{{ order.operator_employee_ident }} </td> <!-- Operator -->
                    <td>{{ order.file_number }}</td> <!-- File number -->
                    <td>{{ order.data_entry_clerk }}</td> <!-- Data entry clerk -->
                    <td>
                        <span v-if="order.note_status === 'Activo'"
                            style="background-color: green; color: white; padding: 2px 5px; border-radius: 3px;">Activo</span>
                        <span v-else-if="order.note_status === 'Asignada'"
                            style="background-color: #5eff00; color: black; padding: 2px 5px; border-radius: 3px;">Asignada</span>
                        <span v-else-if="order.note_status === 'Cancelado'"
                            style="background-color: red; color: white; padding: 2px 5px; border-radius: 3px;">Cancelado</span>
                        <span v-else-if="order.note_status === 'Programado'"
                            style="background-color: #8A2BE2; color: white; padding: 2px 5px; border-radius: 3px;">Programado</span>
                        <span v-else-if="order.note_status === 'Completado'"
                            style="background-color: blue; color: white; padding: 2px 5px; border-radius: 3px;">Completado</span>
                        <span v-else>{{ order.note_status }}</span> <!-- Status -->
                    </td>
                </tr>
            </tbody>
        </table>
        <div class="pagination-container">
            <button @click="prevPageForklift" :disabled="forkliftCurrentPage === 1">
                <img src="@/assets/angulo-izquierdo.png" alt="Previous Page" />
            </button>
            <span v-for="pageNumber in forkliftTotalInProcessPages" :key="pageNumber" @click="goToPageForklift(pageNumber)"
                :class="{ active: pageNumber === forkliftCurrentPage }">{{ pageNumber }}</span>
            <button @click="nextPageForklift" :disabled="forkliftCurrentPage >= forkliftTotalInProcessPages">
                <img src="@/assets/angulo-derecho.png" alt="Next Page" />
            </button>
        </div>
    </div>

    <!-- Table for scheduled orders -->
    <div class="table-container">
        <h2>Órdenes pendientes</h2>
        <table class="table">
            <thead>
                <tr>
                    <th>ID</th>
                    <th>Fecha y hora de solicitud</th>
                    <th>Cliente</th>
                    <th>Origen</th>
                    <th>Hora de inicio</th>
                    <th>Hora de contacto</th>
                    <th>Unidad</th>
                    <th>Operador</th>
                    <th>Expediente</th>
                    <th>Capturista</th>
                    <th>Estatus</th>
                </tr>
            </thead>
            <tbody>
                <tr v-for="(order, index) in forkliftPaginatedScheduledOrders" :key="index">
                    <td>
                        <span
                            style="background-color: #FFC434; color: black; font-weight: bold; padding: 2px 5px; border-radius: 3px;"
                            @click="viewForkliftNoteDetails(order.note_ident)">{{
                            order.note_number }}</span>
                    </td> <!-- ID -->
                    <td><b>Fecha:</b>{{ order.request_time.substring(8, 10) }}/{{ order.request_time.substring(5, 7)
                        }}/{{ order.request_time.substring(0, 4) }} <b>Hora: </b>{{ order.request_time.substring(11, 16)
                        }}</td> <!-- Request time -->
                    <td>{{ order.client }}</td> <!-- Client -->
                    <td>{{ order.source.neighborhood }}, {{ order.source.city }}, {{ order.source.state }}</td>
                    <!-- Source -->
                    <td><!--<b>Fecha: </b><span v-if="order.start_time"> {{ order.start_time.substring(8, 10) }}/{{
                            order.start_time.substring(5, 7) }}/{{ order.start_time.substring(0, 4) }}</span> <span
                            v-else> <b> </b> </span> <b>Hora: </b>-->{{ order.start_time.substring(11, 16) }}</td>
                    <!-- Start time -->
                    <td><!--<b>Fecha: </b><span v-if="order.contact_time"> {{ order.contact_time.substring(8, 10) }}/{{
                            order.contact_time.substring(5, 7) }}/{{ order.contact_time.substring(0, 4) }}</span> <span
                            v-else> <b> </b> </span> <b>Hora: </b>-->{{ order.contact_time.substring(11, 16) }}</td>
                    <!-- Contact time -->
                    <td>{{ order.unit_id }}</td> <!-- Unit -->
                    <td><b>ID:</b>{{ order.operator_employee_ident}} </td> <!-- Operator -->
                    <td>{{ order.file_number }}</td> <!-- File number -->
                    <td>{{ order.data_entry_clerk }}</td> <!-- Data entry clerk -->
                    <td>
                        <span v-if="order.note_status === 'Activo'"
                            style="background-color: green; color: white; padding: 2px 5px; border-radius: 3px;">Activo</span>
                        <span v-else-if="order.note_status === 'Asignada'"
                            style="background-color: #5eff00; color: black; padding: 2px 5px; border-radius: 3px;">Asignada</span>
                        <span v-else-if="order.note_status === 'Cancelado'"
                            style="background-color: red; color: white; padding: 2px 5px; border-radius: 3px;">Cancelado</span>
                        <span v-else-if="order.note_status === 'Programado'"
                            style="background-color: #8A2BE2; color: white; padding: 2px 5px; border-radius: 3px;">Programado</span>
                        <span v-else-if="order.note_status === 'Completado'"
                            style="background-color: blue; color: white; padding: 2px 5px; border-radius: 3px;">Completado</span>
                        <span v-else>{{ order.note_status }}</span> <!-- Status -->
                    </td>
                </tr>
            </tbody>
        </table>
        <div class="pagination-container">
            <button @click="prevScheduledPageForklift" :disabled="forkliftCurrentScheduledPage === 1">
                <img src="@/assets/angulo-izquierdo.png" alt="Previous Page" />
            </button>
            <span v-for="pageNumberScheduled in forkliftTotalScheduledPages" :key="pageNumberScheduled"
                @click="goToScheduledPageForklift(pageNumberScheduled)"
                :class="{ active: pageNumberScheduled === forkliftCurrentScheduledPage }">{{ pageNumberScheduled }}</span>
            <button @click="nextScheduledPageForklift" :disabled="forkliftCurrentScheduledPage >= forkliftTotalScheduledPages">
                <img src="@/assets/angulo-derecho.png" alt="Next Page" />
            </button>
        </div>
    </div>
      </div>


      </div>
      
    </div>
  </div>



    
</template>

<style scoped>
.status-counts {
    margin-left: auto; /* Align the counts to the right */
    text-align: right; /* Align text to the right */
    font-size: 0.75rem; /* Adjust font size for a compact look */
    white-space: nowrap; /* Prevent wrapping */
    overflow: hidden; /* Hide overflowing text */
    text-overflow: ellipsis; /* Add ellipsis for overflowing text */
}
.nav-bar-operations {
    position: absolute; 
    top: 0;
    left: 0;
    width: 100%; 
    height: 10vh; 
    background-color: #FFC434;
    z-index: 1000; 
    background-repeat: no-repeat;
    background-position: center center;
    background-size: contain;
    margin: 0;
}
.logo-button {
    position: absolute; 
    height: 90%; 
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    cursor: pointer;
    margin: 0;
}
.text-operations {
    margin-top: 0vh; /* Adjust this value to ensure it's below the nav bar */
    left: 0;
    width: 100%;
    height: 5vh;
    border-bottom: 1px solid black;
    background-color: #ffffff;
    z-index: 999; /* Ensure it's above other content */
    font-size: 30px;
    display: flex;
    justify-content: space-between; /* Adjust spacing between elements */
    align-items: center; /* Align items vertically */
    overflow: hidden; /* Prevent text overflow */
    gap: 25px;
}
.text-operations h2 {
    font-size: inherit; /* Adjust font size as needed */
    margin: 0;
    padding: 0;
    margin-left: 2vh;
}

.thermometer {
    width: 30%;
    height: 20px;
    background-color: #f2f2f2;
    border-radius: 5px;
    margin-top: 0px;
    display: flex; /* Use flexbox to align items */
    align-items: center; /* Center vertically */
    margin-right: 10px;
}
.bar {
    height: 100%;
    border-radius: 1px; /* Keep border-radius consistent */
}
.bar.active {
    background-color: green;
}
.bar.cancelled {
    background-color: red;
}
.bar.scheduled {
    background-color: #8A2BE2;
}
.bar.completed {
    background-color: blue;
}
.bar.assigned{
    background-color: rgb(238, 255, 6);
}
.action-buttons {
    display: flex;
    justify-content: flex-end; /* Align buttons to the right */
    align-items: center;
    margin-top: 1vh; 
    height: 3vh; 
}
.action-button {
    padding: 5px 10px;
    border: none;
    cursor: pointer;
    border-radius: 5px;
    background-color: #FFC434;
    box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.2);
    transition: background-color 0.3s;
    margin: 0 10px; 
}
.action-button:hover {
    background-color: #dba907;
}
.table-container {
    flex-grow: 1;
    overflow-x: auto;
    margin-top: 0;
    padding: 1vh;
}

.table {
    width: 100%;
    border-collapse: collapse;
    font-size: 0.6em; 
}

.table th, .table td {
    border-left: none;
    border-right: none;
    padding: 5px; 
    text-align: left;
}

.table tbody tr:first-child td {
    border-top: none;
}

.table tbody tr:hover td {
    background-color: #bdbdbd;
    color: rgb(0, 0, 0);
}

.table tbody tr:last-child td {
    border-bottom: none;
}

.table thead input {
    width: 100%;
    box-sizing: border-box;
    padding: 5px;
    margin: 0;
    border-radius: 5px;
    border-width: 1px;
}

.table th{
    border-left: none;
    border-right: none;
    padding: 10px;
    text-align: left
}

.table td {
    white-space: nowrap; 
    overflow: hidden;
    text-overflow: ellipsis; 
}

.pagination-container {
    justify-content: center;
    align-items: center;
    margin-top: 2vh;
    margin-bottom: 2vh;
}
.pagination-container button {
    padding: 5px 10px;
    border: 1px solid #000;
    background-color: #fff;
    cursor: pointer;
    margin: 0 10px;
    background-color: transparent; 
    border: none; 
}
.pagination-container button:disabled {
    color: #ccc;
    cursor: not-allowed;
}
.pagination-container span {
    padding: 5px 10px;
    margin: 0 5px;
    cursor: pointer;
}

.pagination-container span.active {
    background-color: #FFC434;
}
.pagination-container button img {
    width: 25px; 
    height: auto; 
}
.tooltip {
  position: fixed;
  z-index: 9999;
  background-color: rgba(0, 0, 0, 0.8);
  color: #fff;
  padding: 5px 10px;
  border-radius: 5px;
  pointer-events: none; /* Ensure tooltip doesn't interfere with mouse events */
}
.status-count-bubble {
  background-color: rgba(255, 255, 255, 0.8); /* Transparent white background */
  padding: 5px 10px; /* Adjust padding as needed */
  border-radius: 5px; /* Add rounded corners */
}
@media (max-width: 768px) {
    .search-and-filter, .action-buttons {
        flex-direction: column;
        align-items: stretch;
    }
}

@media screen and (min-width: 768px) {
    .table th, .table td {
        width: 8%; 
    }
}

</style>

<script>
import axios from 'axios';

export default {
    data() {
        return {
            activeTab: 'tab1', // Default active tab
            assistanceStatusCounts: {
                Activo: 1, // Example hardcoded value
                Cancelado: 1, // Example hardcoded value
                Programado: 1, // Example hardcoded value
                Completado: 1, // Example hardcoded value
                Asignada: 1
            },
            auxiliarStatusCounts: {
                Activo: 1, // Example hardcoded value
                Cancelado: 1, // Example hardcoded value
                Programado: 1, // Example hardcoded value
                Completado: 1, // Example hardcoded value
                Asignada: 1
            },
            lotStatusCounts: {
                Activo: 1, // Example hardcoded value
                Cancelado: 1, // Example hardcoded value
                Programado: 1, // Example hardcoded value
                Completado: 1, // Example hardcoded value
                Asignada: 1
            },
            assistanceHoverStatusCount: '',
            auxiliarHoverStatusCount: '',
            lotHoverStatusCount: '',
            hoverStatusCountStyle: {
                position: 'absolute',
                top: 0,
                left: 0
            },
            assistanceFilters: {
                status : ["1","5"],
                type: ["2"]
            },
            auxiliarFilters: {
                status : ["1","5"],
                type: ["1"]
            },
            lotFilters: {
                status : ["1","5"],
                type: ["4"]
            },
            assistanceCurrentPage: 1,
            assistanceCurrentScheduledPage: 1,
            assistancePageSize: 10,
            assistancePageSizeScheduled: 20,
            assistanceScheduledOrders: [],
            assistanceInProcessOrders: [],
            assistanceOriginalOrders: [],
            assistance_n_inProcessOrders: 0,
            assistance_n_scheduledOrders: 0,
            
            auxiliarCurrentPage: 1,
            auxiliarCurrentScheduledPage: 1,
            auxiliarPageSize: 10,
            auxiliarPageSizeScheduled: 20,
            auxiliarScheduledOrders: [],
            auxiliarInProcessOrders: [],
            auxiliarOriginalOrders: [],
            auxiliar_n_inProcessOrders: 0,
            auxiliar_n_scheduledOrders: 0,

            lotCurrentPage: 1,
            lotCurrentScheduledPage: 1,
            lotPageSize: 10,
            lotPageSizeScheduled: 20,
            lotScheduledOrders: [],
            lotInProcessOrders: [],
            lotOriginalOrders: [],
            lot_n_inProcessOrders: 0,
            lot_n_scheduledOrders: 0,
            /// Replicated logic for forklift notes
            forkliftStatusCounts: {
                Activo: 1, // Example hardcoded value
                Cancelado: 1, // Example hardcoded value
                Programado: 1, // Example hardcoded value
                Completado: 1, // Example hardcoded value
                Asignada: 1
            },
            forkliftHoverStatusCount: '',
            forkliftHoverStatusCountStyle: {
                position: 'absolute',
                top: 0,
                left: 0
            },
            forkliftFilters: {
                note_status : ["1","5"],
                type: ["1","2","3","4"]
            },
            forkliftCurrentPage: 1,
            forkliftCurrentScheduledPage: 1,
            forkliftPageSize: 10,
            forkliftPageSizeScheduled: 20,
            forkliftScheduledOrders: [],
            forkliftInProcessOrders: [],
            forkliftOriginalOrders: [],
            forklift_n_inProcessOrders: 0,
            forklift_n_scheduledOrders: 0,
        };
    },
    computed: {
        totalAssistanceStatusCounts() {
            // Ensure it's an object and sum all values
            return Object.values(this.assistanceStatusCounts || {}).reduce((sum, count) => sum + (count || 0), 0);
        },
        totalAuxiliarStatusCounts() {
            // Ensure it's an object and sum all values
            return Object.values(this.auxiliarStatusCounts || {}).reduce((sum, count) => sum + (count || 0), 0);
        },
        totalForkliftStatusCounts() {
            // Ensure it's an object and sum all values
            return Object.values(this.forkliftStatusCounts || {}).reduce((sum, count) => sum + (count || 0), 0);
        },
        totalLotStatusCounts() {
            // Ensure it's an object and sum all values
            return Object.values(this.lotStatusCounts || {}).reduce((sum, count) => sum + (count || 0), 0);
        },
        assistancePaginatedInProcessOrders() {
            //**Contains page of orders with status NE to "Programado" */
            return this.assistanceInProcessOrders
        },
        assistanceTotalInProcessPages() {
            return Math.ceil((this.assistanceStatusCounts.Activo + this.assistanceStatusCounts.Asignada) / this.assistancePageSize);
            // return Math.ceil(this.assistance_n_inProcessOrders / this.assistancePageSize);
        },
        assistancePaginatedScheduledOrders() {
            //**Contains page of orders with status equal to "Programado" */
            return this.assistanceScheduledOrders
        },
        assistanceTotalScheduledPages() {
            //** Computes the number of pages for the scheduled orders based on the number of scheduled notes / page size */
            return Math.ceil(this.assistanceStatusCounts.Programado/this.assistancePageSizeScheduled)
        },
        auxiliarPaginatedInProcessOrders() {
            //**Contains page of orders with status NE to "Programado" */
            return this.auxiliarInProcessOrders
        },
        auxiliarTotalInProcessPages() {
            return Math.ceil((this.auxiliarStatusCounts.Activo + this.auxiliarStatusCounts.Asignada)/ this.auxiliarPageSize);
        },
        auxiliarPaginatedScheduledOrders() {
            //**Contains page of orders with status equal to "Programado" */
            return this.auxiliarScheduledOrders
        },
        auxiliarTotalScheduledPages() {
            //** Computes the number of pages for the scheduled orders based on the number of scheduled notes / page size */
            return Math.ceil(this.auxiliarStatusCounts.Programado/this.auxiliarPageSizeScheduled)
        },
        lotPaginatedInProcessOrders() {
            //**Contains page of orders with status NE to "Programado" */
            return this.lotInProcessOrders
        },
        lotTotalInProcessPages() {
            return Math.ceil((this.lotStatusCounts.Activo + this.lotStatusCounts.Asignada) / this.lotPageSize);
        },
        lotPaginatedScheduledOrders() {
            //**Contains page of orders with status equal to "Programado" */
            return this.lotScheduledOrders
        },
        lotTotalScheduledPages() {
            //** Computes the number of pages for the scheduled orders based on the number of scheduled notes / page size */
            return Math.ceil(this.lotStatusCounts.Programado/this.lotPageSizeScheduled)
        },
        forkliftPaginatedInProcessOrders() {
            //**Contains page of orders with status NE to "Programado" */
            return this.forkliftInProcessOrders
        },
        forkliftTotalInProcessPages() {
            return Math.ceil((this.forkliftStatusCounts.Activo + this.forkliftStatusCounts.Asignada)  / this.forkliftPageSize);
        },
        forkliftPaginatedScheduledOrders() {
            //**Contains page of orders with status equal to "Programado" */
            return this.forkliftScheduledOrders
        },
        forkliftTotalScheduledPages() {
            //** Computes the number of pages for the scheduled orders based on the number of scheduled notes / page size */
            return Math.ceil(this.forkliftStatusCounts.Programado/this.forkliftPageSizeScheduled)
        },
    },
    mounted() {
        this.fetchStatisticsAssistance();
        this.fetchScheduledAssistance(); //called with default arguments 
        this.fetchInProcessAssistance(); //called with default arguments 
        this.fetchStatisticsAuxiliar();
        this.fetchScheduledAuxiliar(); //called with default arguments 
        this.fetchInProcessAuxiliar(); //called with default arguments 
        this.fetchStatisticsLot();
        this.fetchScheduledLot(); //called with default arguments   
        this.fetchInProcessLot(); //called with default arguments 
        this.fetchStatisticsForklift();
        this.fetchScheduledForklift();
        this.fetchInProcessForklift();
    },
    methods: {
        calculateGradient(requestTime, expectedMinutes) {
        const currentTime = new Date();
        const requestDate = new Date(requestTime);

        if (!expectedMinutes) {
            return 'rgb(64, 64, 64)'; // Dark grey with white text
        }

        // Calculate the expected time by adding the minutes to the request time
        const expectedDate = new Date(requestDate.getTime() + expectedMinutes * 60 * 1000);

        // Calculate the total duration and elapsed time in milliseconds
        const totalTime = expectedDate - requestDate;
        const elapsedTime = currentTime - requestDate;

        // Compute the percentage of time elapsed
        const percentage = Math.min(Math.max(elapsedTime / totalTime, 0), 1); // Clamp between 0 and 1

        // Calculate the gradient color
        const darkenFactor = 0.65;
        const red = Math.round(255 * percentage * darkenFactor); //increases as time progresses
        const green = Math.round(255 * (1 - percentage) * darkenFactor); //decreases as time progresses

        return `rgb(${red}, ${green}, 0)`; // Returns the calculated color
    },
        showStatusCountAssistance(status, event) {
            const count = this.assistanceStatusCounts[status];
            this.assistanceHoverStatusCount = `${status}: ${count}`;
            this.hoverStatusCountStyle.top = `${event.clientY}px`;
            this.hoverStatusCountStyle.left = `${event.clientX}px`;
        },
        showStatusCountAuxiliar(status, event) {
            const count = this.auxiliarStatusCounts[status];
            this.auxiliarHoverStatusCount = `${status}: ${count}`;
            this.hoverStatusCountStyle.top = `${event.clientY}px`;
            this.hoverStatusCountStyle.left = `${event.clientX}px`;
        },
        showStatusCountLot(status, event) {
            const count = this.lotStatusCounts[status];
            this.lotHoverStatusCount = `${status}: ${count}`;
            this.hoverStatusCountStyle.top = `${event.clientY}px`;
            this.hoverStatusCountStyle.left = `${event.clientX}px`;
        },
        showStatusCountForklift(status, event) {
            const count = this.forkliftStatusCounts[status];
            this.forkliftHoverStatusCount = `${status}: ${count}`;
            this.forkliftHoverStatusCountStyle.top = `${event.clientY}px`;
            this.forkliftHoverStatusCountStyle.left = `${event.clientX}px`;
        },
        hideStatusCountAssistance() {
            this.assistanceHoverStatusCount = '';
        },
        hideStatusCountAuxiliar() {
            this.auxiliarHoverStatusCount = '';
        },
        hideStatusCountLot() {
            this.lotHoverStatusCount = '';
        },
        hideStatusCountForklift() {
            this.forkliftHoverStatusCount = '';
        },
        viewNoteDetails(notetype, noteID) {
            // Calls EditNote component for aux or assistance notes

            this.$router.push({ name: 'EditNote', params: { notetype, noteID } });

        },
        viewForkliftNoteDetails(noteID) {
            // Calls EditNote component for forklift notes
            const notetype = "Montacargas";
                    this.$router.push({ name: 'EditNote', params: { notetype, noteID } });

        },
        goToPageAssistance(pageNumber) {
            this.assistanceCurrentPage = pageNumber;
            const page = this.assistanceCurrentPage
            this.fetchInProcessAssistance(page);
        },
        goToPageAuxiliar(pageNumber) {
            this.auxiliarCurrentPage = pageNumber;
            const page = this.auxiliarCurrentPage
            this.fetchInProcessAuxiliar(page);
        },
        goToPageLot(pageNumber) {
            this.lotCurrentPage = pageNumber;
            const page = this.lotCurrentPage
            this.fetchInProcessLot(page);
        },
        goToPageForklift(pageNumber) {
            this.forkliftCurrentPage = pageNumber;
            const page = this.forkliftCurrentPage
            this.fetchInProcessForklift(page);
        },
        goToScheduledPageAssistance(pageNumberScheduled) {
            this.assistanceCurrentScheduledPage = pageNumberScheduled;
            const page = this.assistanceCurrentScheduledPage
            this.fetchScheduledAssistance(page);
        },
        goToScheduledPageAuxiliar(pageNumberScheduled) {
            this.auxiliarCurrentScheduledPage = pageNumberScheduled;
            const page = this.auxiliarCurrentScheduledPage
            this.fetchScheduledAuxiliar(page);
        },
        goToScheduledPageLot(pageNumberScheduled) {
            this.lotCurrentScheduledPage = pageNumberScheduled;
            const page = this.lotCurrentScheduledPage
            this.fetchScheduledLot(page);
        },
        goToScheduledPageForklift(pageNumberScheduled) {
            this.forkliftCurrentScheduledPage = pageNumberScheduled;
            const page = this.forkliftCurrentScheduledPage
            this.fetchScheduledForklift(page);
        },
        backMainPanel() {
            window.location.href = '/main-panel';
        },
        applyFiltersAssistance() {
            this.fetchInProcessAssistance()
            this.fetchStatisticsAssistance()
        },
        applyFiltersAuxiliar() {
            this.fetchInProcessAuxiliar()
            this.fetchStatisticsAuxiliar()
        },
        applyFiltersLot() {
            this.fetchInProcessLot()
            this.fetchStatisticsLot()
        },
        applyFiltersForklift() {
            this.fetchInProcessForklift()
            this.fetchStatisticsForklift()
        },
        pricesRequests() {
            window.location.href = '/prices-requests';
        },
        newOrder() {
            window.location.href = '/create-new-order';
        },
        rastreoUnidades() {
            window.location.href = '/main-map'
        },
        statusUnits() {
            window.location.href = '/ver-estatus-unidades'
        },
        globalfilters() {
            window.location.href = '/filtros-globales';
        },
        forkliftfilters() {
            window.location.href = '/filtros-montacargas';
        },
        ordenesLote() {},
        async fetchStatisticsAssistance(){
            let response 
            const apiDirectory = process.env.API_DIRECTORY;
            let request_filters = this.assistanceFilters;
            // Request schedules status as or condition (Check view for details)
            request_filters.scheduled_orders = 1;
            try{
            response = await axios.post(`${apiDirectory}/get-services-metadata-statistics`,this.assistanceFilters);
                const statusDict = {
                    1: "Activo",
                    2: "Cancelado",
                    3: "Programado",
                    4: "Completado",
                    5: "Asignada"
                };
                // Initialization to avoid undefined on bar
                let statusCounts = {
                    "Activo":0,
                    "Cancelado":0,
                    "Programado":0,
                    "Completado":0,
                    "Asignada":0
                };
                // Assign based on response 
                // console.log(response.data);
                const responseData = response.data;
                // console.log(responseData);
                responseData.forEach(item => {
                    const status = statusDict[item.status]; // Get the name from the statusDict
                    const count = item.count;
                    statusCounts[status] = count;
                });
                // Returned from statistics endpoint 
                this.assistanceStatusCounts = statusCounts;
            }
            catch(error){
                console.error("There was an error fetching the statistics",error)
            }
        },
        async fetchStatisticsAuxiliar(){
            let response 
            const apiDirectory = process.env.API_DIRECTORY;
            let request_filters = this.auxiliarFilters;
            console.log(request_filters);
            // Request schedules status as or condition (Check view for details)
            request_filters.scheduled_orders = 1;
            try{
            response = await axios.post(`${apiDirectory}/get-services-metadata-statistics`,request_filters)
                const statusDict = {
                    1: "Activo",
                    2: "Cancelado",
                    3: "Programado",
                    4: "Completado",
                    5: "Asignada"
                };
                // Initialization to avoid undefined on bar
                let statusCounts = {
                    "Activo":0,
                    "Cancelado":0,
                    "Programado":0,
                    "Completado":0,
                    "Asignada":0
                };
                // Assign based on response 
                response.data.forEach(item => {
                    const status = statusDict[item.status]; // Get the name from the statusDict
                    const count = item.count;
                    statusCounts[status] = count;
                });
                // Returned from statistics endpoint 
                this.auxiliarStatusCounts = statusCounts;
            }
            catch(error){
                console.error("There was an error fetching the statistics",error)
            }
        },
        async fetchStatisticsLot(){
            let response 
            const apiDirectory = process.env.API_DIRECTORY;
            let request_filters = this.lotFilters;
            // Request schedules status as or condition (Check view for details)
            request_filters.scheduled_orders = 1;
            try{
            response = await axios.post(`${apiDirectory}/get-services-metadata-statistics`,request_filters)
                const statusDict = {
                    1: "Activo",
                    2: "Cancelado",
                    3: "Programado",
                    4: "Completado",
                    5: "Asignada"
                };
                // Initialization to avoid undefined on bar
                let statusCounts = {
                    "Activo":0,
                    "Cancelado":0,
                    "Programado":0,
                    "Completado":0,
                    "Asignada":0
                };
                // Assign based on response 
                response.data.forEach(item => {
                    const status = statusDict[item.status]; // Get the name from the statusDict
                    const count = item.count;
                    statusCounts[status] = count;
                });
                // Returned from statistics endpoint 
                this.lotStatusCounts = statusCounts;
            }
            catch(error){
                console.error("There was an error fetching the statistics",error)
            }
        },
        async fetchStatisticsForklift(){
            let response 
            const apiDirectory = process.env.API_DIRECTORY;
            let request_filters = this.forkliftFilters;
            // Request schedules status as or condition (Check view for details)
            request_filters.scheduled_orders = 1;
            try{
            response = await axios.post(`${apiDirectory}/get-services-metadata-statistics`,request_filters)
                const statusDict = {
                    1: "Activo",
                    2: "Cancelado",
                    3: "Programado",
                    4: "Completado",
                    5: "Asignada"
                };
                // Initialization to avoid undefined on bar
                let statusCounts = {
                    "Activo":0,
                    "Cancelado":0,
                    "Programado":0,
                    "Completado":0,
                    "Asignada":0
                };
                // Assign based on response 
                response.data.forEach(item => {
                    const status = statusDict[item.note_status]; // Get the name from the statusDict
                    const count = item.count;
                    statusCounts[status] = count;
                });
                // Returned from statistics endpoint 
                this.forkliftStatusCounts = statusCounts;
            }
            catch(error){
                console.error("There was an error fetching the statistics",error)
            }
        },
        async fetchScheduledAssistance(page = 1) {
            /**Fetches the scheduled notes */
            let response
            const apiDirectory = process.env.API_DIRECTORY;
            try {
                response = await axios.post(`${apiDirectory}/get-services-metadata-filtered?page=${page}&page_size=${this.assistancePageSizeScheduled}`,{"status":["3"], "type":["2"]})
                const responseData = response.data.results.filter(note => note.type === "Asistencia víal");
                // Returned directly from paginator object
                responseData.forEach(order => {
                    if (!('contact_time' in order) || order.contact_time === null) {
                        order.contact_time = '';
                    }
                    if (!('start_time' in order) || order.start_time === null) {
                        order.start_time = '';
                    }
                    if (!('unit_id' in order) || order.unit_id === null) {
                        order.unit_id = 'N/A';
                    }
                    if (!('operator_first_name' in order) || order.operator_first_name === null) {
                        order.operator_first_name = 'N/A';
                    }
                    if (!('operator_employee_ident' in order) || order.operator_employee_ident === null) {
                        order.operator_employee_ident = 'N/A';
                    }
                });
                this.assistanceScheduledOrders = responseData
                this.assistance_n_scheduledOrders = responseData.length
                // this.assistance_n_scheduledOrders = response.data.results.count
                this.assistanceOriginalOrders = [...responseData]
            }
            catch (error) {
                console.error('Error fetching data from API:', error)
            }
        },
        async fetchScheduledAuxiliar(page = 1) {
            /**Fetches the scheduled notes */
            let response
            const apiDirectory = process.env.API_DIRECTORY;
            try {
                response = await axios.post(`${apiDirectory}/get-services-metadata-filtered?page=${page}&page_size=${this.auxiliarPageSizeScheduled}`,{"status":["3"], "type": ["1"]})
                const responseData = response.data.results.filter(note => note.type === "Auxilio víal");
                // Returned directly from paginator object
                responseData.forEach(order => {
                    if (!('contact_time' in order) || order.contact_time === null) {
                        order.contact_time = '';
                    }
                    if (!('start_time' in order) || order.start_time === null) {
                        order.start_time = '';
                    }
                    if (!('unit_id' in order) || order.unit_id === null) {
                        order.unit_id = 'N/A';
                    }
                    if (!('operator_first_name' in order) || order.operator_first_name === null) {
                        order.operator_first_name = 'N/A';
                    }
                    if (!('operator_employee_ident' in order) || order.operator_employee_ident === null) {
                        order.operator_employee_ident = 'N/A';
                    }
                });
                this.auxiliarScheduledOrders = responseData
                this.auxiliar_n_scheduledOrders = responseData.length
                console.log(this.auxiliar_n_scheduledOrders);
                this.auxiliarOriginalOrders = [...responseData]
            }
            catch (error) {
                console.error('Error fetching data from API:', error)
            }
        },
        async fetchScheduledLot(page = 1) {
            /**Fetches the scheduled notes */
            let response
            const apiDirectory = process.env.API_DIRECTORY;
            try {
                response = await axios.post(`${apiDirectory}/get-services-metadata-filtered?page=${page}&page_size=${this.LotPageSizeScheduled}`,{"status":["3"], "type":["4"]})
                const responseData = response.data.results.filter(note => note.type === "Lote");
                // Returned directly from paginator object
                responseData.forEach(order => {
                    if (!('contact_time' in order) || order.contact_time === null) {
                        order.contact_time = '';
                    }
                    if (!('start_time' in order) || order.start_time === null) {
                        order.start_time = '';
                    }
                    if (!('unit_id' in order) || order.unit_id === null) {
                        order.unit_id = 'N/A';
                    }
                    if (!('operator_first_name' in order) || order.operator_first_name === null) {
                        order.operator_first_name = 'N/A';
                    }
                    if (!('operator_employee_ident' in order) || order.operator_employee_ident === null) {
                        order.operator_employee_ident = 'N/A';
                    }
                });
                this.lotScheduledOrders = responseData
                this.lot_n_scheduledOrders = responseData.length
                this.lotOriginalOrders = [...responseData]
            }
            catch (error) {
                console.error('Error fetching data from API:', error)
            }
        },
        async fetchScheduledForklift(page = 1) {
            /**Fetches the scheduled notes */
            let response
            const apiDirectory = process.env.API_DIRECTORY;
            try {
                response = await axios.post(`${apiDirectory}/get-services-metadata-filtered?page=${page}&page_size=${this.forkliftPageSizeScheduled}`,{"note_status":[3], "type":["3"]})
                const responseData = response.data.results;
                // Returned directly from paginator object
                responseData.forEach(order => {
                    if (!('contact_time' in order) || order.contact_time === null) {
                        order.contact_time = '';
                    }
                    if (!('start_time' in order) || order.start_time === null) {
                        order.start_time = '';
                    }
                    if (!('unit_id' in order) || order.unit_id === null) {
                        order.unit_id = 'N/A';
                    }
                    if (!('operator_first_name' in order) || order.operator_first_name === null) {
                        order.operator_first_name = 'N/A';
                    }
                    if (!('operator_employee_ident' in order) || order.operator_employee_ident === null) {
                        order.operator_employee_ident = 'N/A';
                    }
                });
                this.forkliftScheduledOrders = responseData
                this.forklift_n_scheduledOrders = response.data.count
                this.forkliftOriginalOrders = [...responseData]
            }
            catch (error) {
                console.error('Error fetching data from API:', error)
            }
        },
        async fetchInProcessAssistance(page = 1) {
            /**Fetches the in process notes using the provided filters*/
            let response
            const apiDirectory = process.env.API_DIRECTORY;
            try {
                response = await axios.post(`${apiDirectory}/get-services-metadata-filtered?page=${page}&page_size=${this.assistancePageSize}`,this.assistanceFilters)
                const responseData = response.data.results.filter(note => note.type === "Asistencia víal");
                responseData.forEach(order => {
                    if (!('contact_time' in order) || order.contact_time === null) {
                        order.contact_time = '';
                    }
                    if (!('start_time' in order) || order.start_time === null) {
                        order.start_time = '';
                    }
                    if (!('unit_id' in order) || order.unit_id === null) {
                        order.unit_id = 'N/A';
                    }
                    if (!('operator_first_name' in order) || order.operator_first_name === null) {
                        order.operator_first_name = 'N/A';
                    }
                    if (!('operator_employee_ident' in order) || order.operator_employee_ident === null) {
                        order.operator_employee_ident = 'N/A';
                    }
                });
                this.assistanceInProcessOrders = responseData
                this.assistance_n_inProcessOrders = responseData.length
                this.assistanceOriginalOrders = [...responseData]
            }
            catch (error) {
                console.error('Error fetching data from API:', error)
            }
        },
        async fetchInProcessAuxiliar(page = 1) {
            /**Fetches the in process notes using the provided filters*/
            let response
            const apiDirectory = process.env.API_DIRECTORY;
            try {
                response = await axios.post(`${apiDirectory}/get-services-metadata-filtered?page=${page}&page_size=${this.auxiliarPageSize}`,this.auxiliarFilters)
                const responseData = response.data.results.filter(note => note.type === "Auxilio víal");
                responseData.forEach(order => {
                    if (!('contact_time' in order) || order.contact_time === null) {
                        order.contact_time = '';
                    }
                    if (!('start_time' in order) || order.start_time === null) {
                        order.start_time = '';
                    }
                    if (!('unit_id' in order) || order.unit_id === null) {
                        order.unit_id = 'N/A';
                    }
                    if (!('operator_first_name' in order) || order.operator_first_name === null) {
                        order.operator_first_name = 'N/A';
                    }
                    if (!('operator_employee_ident' in order) || order.operator_employee_ident === null) {
                        order.operator_employee_ident = 'N/A';
                    }
                });
                this.auxiliarInProcessOrders = responseData
                this.auxiliar_n_inProcessOrders = responseData.length
                this.auxiliarOriginalOrders = [...responseData]
            }
            catch (error) {
                console.error('Error fetching data from API:', error)
            }
        },
        async fetchInProcessLot(page = 1) {
            /**Fetches the in process notes using the provided filters*/
            let response
            const apiDirectory = process.env.API_DIRECTORY;
            try {
                response = await axios.post(`${apiDirectory}/get-services-metadata-filtered?page=${page}&page_size=${this.lotPageSize}`,this.lotFilters)
                const responseData = response.data.results.filter(note => note.type === "Lote");
                responseData.forEach(order => {
                    if (!('contact_time' in order) || order.contact_time === null) {
                        order.contact_time = '';
                    }
                    if (!('start_time' in order) || order.start_time === null) {
                        order.start_time = '';
                    }
                    if (!('unit_id' in order) || order.unit_id === null) {
                        order.unit_id = 'N/A';
                    }
                    if (!('operator_first_name' in order) || order.operator_first_name === null) {
                        order.operator_first_name = 'N/A';
                    }
                    if (!('operator_employee_ident' in order) || order.operator_employee_ident === null) {
                        order.operator_employee_ident = 'N/A';
                    }
                });
                this.lotInProcessOrders = responseData
                this.lot_n_inProcessOrders = responseData.length
                this.lotOriginalOrders = [...responseData]
            }
            catch (error) {
                console.error('Error fetching data from API:', error)
            }
        },
        async fetchInProcessForklift(page = 1) {
            /**Fetches the in process notes using the provided filters*/
            let response
            const apiDirectory = process.env.API_DIRECTORY;
            try {
                response = await axios.post(`${apiDirectory}/get-forklift-metadata-filtered?page=${page}&page_size=${this.forkliftPageSize}`,this.forkliftFilters)
                const responseData = response.data.results.filter(note => note.type === "Montacargas");
                responseData.forEach(order => {
                    if (!('contact_time' in order) || order.contact_time === null) {
                        order.contact_time = '';
                    }
                    if (!('start_time' in order) || order.start_time === null) {
                        order.start_time = '';
                    }
                    if (!('unit_id' in order) || order.unit_id === null) {
                        order.unit_id = 'N/A';
                    }
                    if (!('operator_first_name' in order) || order.operator_first_name === null) {
                        order.operator_first_name = 'N/A';
                    }
                    if (!('operator_employee_ident' in order) || order.operator_employee_ident === null) {
                        order.operator_employee_ident = 'N/A';
                    }
                });
                this.forkliftInProcessOrders = responseData
                this.forklift_n_inProcessOrders = response.data.count
                this.forkliftOriginalOrders = [...responseData]
            }
            catch (error) {
                console.error('Error fetching data from API:', error)
            }
        },
        nextPageAssistance() {
            if (this.assistanceCurrentPage < this.assistanceTotalInProcessPages) this.assistanceCurrentPage++;
            const page = this.assistanceCurrentPage
            this.fetchInProcessAssistance(page);
        },
        nextPageAuxiliar() {
            if (this.auxiliarCurrentPage < this.auxiliarTotalInProcessPages) this.auxiliarCurrentPage++;
            const page = this.auxiliarCurrentPage
            this.fetchInProcessAuxiliar(page);
        },
        nextPageLot() {
            if (this.lotCurrentPage < this.lotTotalInProcessPages) this.lotCurrentPage++;
            const page = this.lotCurrentPage
            this.fetchInProcessLot(page);
        },
        nextPageForklift() {
            if (this.forkliftCurrentPage < this.forkliftTotalInProcessPages) this.forkliftCurrentPage++;
            const page = this.forkliftCurrentPage
            this.fetchInProcessForklift(page);
        },

        prevPageAssistance() {
            if (this.assistanceCurrentPage > 1) this.assistanceCurrentPage--;
            const page = this.assistanceCurrentPage
            this.fetchInProcessAssistance(page);
        },
        prevPageAuxiliar() {
            if (this.auxiliarCurrentPage > 1) this.auxiliarCurrentPage--;
            const page = this.auxiliarCurrentPage
            this.fetchInProcessAuxiliar(page);
        },
        prevPageLot() {
            if (this.lotCurrentPage > 1) this.lotCurrentPage--;
            const page = this.lotCurrentPage
            this.fetchInProcessLot(page);
        },
        prevPageForklift() {
            if (this.forkliftCurrentPage > 1) this.forkliftCurrentPage--;
            const page = this.forkliftCurrentPage
            this.fetchInProcessForklift(page);
        },
        nextScheduledPageAssistance() {
            if (this.assistanceCurrentScheduledPage < this.assistanceTotalScheduledPages) this.assistanceCurrentScheduledPage++;
            const page = this.assistanceCurrentScheduledPage
            this.fetchScheduledAssistance(page);
        },
        nextScheduledPageAuxiliar() {
            if (this.auxiliarCurrentScheduledPage < this.auxiliarTotalScheduledPages) this.auxiliarCurrentScheduledPage++;
            const page = this.auxiliarCurrentScheduledPage
            this.fetchScheduledAuxiliar(page);
        },
        nextScheduledPageLot() {
            if (this.lotCurrentScheduledPage < this.lotTotalScheduledPages) this.lotCurrentScheduledPage++;
            const page = this.lotCurrentScheduledPage
            this.fetchScheduledLot(page);
        },
        nextScheduledPageForklift() {
            if (this.forkliftCurrentScheduledPage < this.forkliftTotalScheduledPages) this.forkliftCurrentScheduledPage++;
            const page = this.forkliftCurrentScheduledPage
            this.fetchScheduledForklift(page);
        },
        prevScheduledPageAssistance() {
            if (this.assistanceCurrentScheduledPage > 1) this.assistanceCurrentScheduledPage--;
            const page = this.assistanceCurrentScheduledPage
            this.fetchScheduledAssistance(page);
        },
        prevScheduledPageAuxiliar() {
            if (this.auxiliarCurrentScheduledPage > 1) this.auxiliarCurrentScheduledPage--;
            const page = this.auxiliarCurrentScheduledPage
            this.fetchScheduledAuxiliar(page);
        },
        prevScheduledPageLot() {
            if (this.lotCurrentScheduledPage > 1) this.lotCurrentScheduledPage--;
            const page = this.lotCurrentScheduledPage
            this.fetchScheduledLot(page);
        },
        prevScheduledPageForklift() {
            if (this.forkliftCurrentScheduledPage > 1) this.forkliftCurrentScheduledPage--;
            const page = this.forkliftCurrentScheduledPage
            this.fetchScheduledForklift(page);
        },
    }
}
</script>